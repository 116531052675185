import { render, staticRenderFns } from "./TimeslotList.vue?vue&type=template&id=4373d3ae&scoped=true&"
import script from "./TimeslotList.vue?vue&type=script&lang=js&"
export * from "./TimeslotList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4373d3ae",
  null
  
)

export default component.exports