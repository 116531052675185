<template>
  <div>
    <table class="text-xs text-left w-full mt-4 border-collapse border border-gray-300">
      <thead class="bg-gray-300">
        <th class="w-1/5 p-2"> {{ $t('stats_timeslot_name') }}</th>
        <th class="w-1/5 p-2"> {{ $t('stats_timeslot_12hr') }}</th>
        <th class="w-1/5 p-2"> {{ $t('stats_timeslot_48hr') }}</th>
        <th class="w-1/5 p-2"> {{ $t('stats_timeslot_far') }}</th>
        <th class="w-1/5 p-2"> {{ $t('stats_timeslot_total') }}</th>
      </thead>
      <tbody>
        <tr v-for="(stat, index) in timeslotStats" :key="index"
          class="text-center">
          <td class="p-2 border border-gray-300 text-blue-500 hover:underline"
            @click="onStatSelect(stat.user_id)">
            {{ stat.user_name }}
          </td>
          <td class="p-2 border border-gray-300">{{ stat.same_day }}</td>
          <td class="p-2 border border-gray-300">{{ stat.forty_eight }}</td>
          <td class="p-2 border border-gray-300">{{ stat.long }}</td>
          <td class="p-2 border border-gray-300">{{ stat.total }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { actions, getters } from "@/constants/state";
import { mapActions, mapGetters } from "vuex";

export default {
  async mounted() {
    this.$emit('setLoading', true);
    try {
      await this.getTimeslotStats();
      console.log('DEBUG: getTimeslotStats', this.timeslotStats);
    } catch (error) {
      console.error('DEBUG: mounted', error);
    }
      this.$emit('setLoading', false);
  },
  methods: {
    ...mapActions({
      getTimeslotStats: actions.STATS_FETCH_TIMESLOTS_ACTION
    }),
    onStatSelect(userId) {
      console.log('DEBUG: userId', userId);
      this.$router.push(`/stats/timeslot/${userId}`)
    }
  },
  computed: {
    ...mapGetters({
      timeslotStats: getters.STATS_TIMESLOTS_GETTER
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
